import { DropboxAuth } from "dropbox";

const CLIENT_ID = process.env.CLIENT_ID;

function getAccessTokenFromUrl() {
  return new URLSearchParams(window.location.hash.substr(1)).get(
    "access_token"
  );
}

function showPageSection(id: string) {
  document.getElementById(id).style.display = "block";
}

const token = getAccessTokenFromUrl();
if (token) {
  document.getElementById("token").innerText = token;
  showPageSection("authenticated");
} else {
  const dropbox = new DropboxAuth({ clientId: CLIENT_ID });
  const url = dropbox.getAuthenticationUrl(location.href);
  (<HTMLLinkElement>document.getElementById("link")).href = url;
  showPageSection("pre-authenticate");
}
